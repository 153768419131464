<template lang="pug">
	window
		template(slot="header")
			span Notificación

		template(v-if="messageId ===  'successful-payment'")
			v-card-text Has sido subscrito exitosamente. Tu cuenta será actualizada en breve.

		template(v-else-if="messageId === 'error'")
			v-list
				v-list-tile
					v-list-tile-avatar
						v-icon(color="error" large) mdi-close-circle
					v-list-tile-content
						v-list-tile-title(class="error--text"): strong Ha habido un error.

			v-card-text Vuelve a intentarlo o comunícate con nosotros para ayudarte

		v-btn(color="primary" to="/") Volver 
</template>

<script>
import Window from '@/modules/access/components/Window.component';
import firebase from '@/modules/common/firebase';

const analytics = firebase.analytics();

const MESSAGES_AVAILABLE = ['downgrade-plan', 'error', 'successful-payment'];
export default {
	name: 'AccessMessage',
	components: {
		Window
	},

	data: () => {
		return {
			messageId: ''
		}
	},

	mounted() {
		const id = this.$route.params.id;
		const {price, plan} = this.$route.query;

		if(!MESSAGES_AVAILABLE.includes(id)) {
			return this.$router.push({name: 'home'});
		} 
		
		this.messageId = this.$route.params.id;
		
		if(id === 'successful-payment' && price && plan) {
			analytics.logEvent('subscription_new', {
				plan_id: plan,
				value: price
			});
		}
	}
}
</script>

<style lang="less" scoped>

</style>


